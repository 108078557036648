import AccountEntry from "@/components/Account/AccountEntry";
import { observer } from "mobx-react-lite";
import type { NextPageWithLayout } from "../_app";

const Entry: NextPageWithLayout = observer(() => {
  return <AccountEntry />;
});

// export async function getServerSideProps({ locale = "en" }) {
//   return {
//     props: {
//       ...(await serverSideTranslations(locale, ["common"])),
//     },
//   };
// }
Entry.getLayout = (page) => {
  return page;
};

export default Entry;
